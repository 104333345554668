export default {
  data() {
    return {
      controleLayout: {
        exibirCards: true,
        agrupamentos: {
          tipoContrato: true,
          holding: true,
          extensoes: true,
          fornecedor: true,
          cliente: true,
        },
      },
    };
  },
};

<template>
  <v-card style="margin-bottom:1em" class="PlanejamentoContratoFiltros">
    <v-card-title>
      <span class="title">{{ $tc('title.filtro', 2) }}</span>

      <v-radio-group class="PlanejamentoContratoFiltros__Opcoes"
        v-model="tipoFiltro"
        v-show="filtrosAbertos"
        row>
        <v-radio :label="$tc('label.em_comum', 1)" value="comum"></v-radio>
        <v-radio :label="$t('label.todos')" value="todos"></v-radio>
      </v-radio-group>

      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass">
        fa-thumbtack
      </v-icon>
      <v-badge
      bordered
      color="orange"
      icon="mdi-lock"
      overlap
      v-if="habilitaIconBadge">
      <v-icon @click="toggleFiltros">filter_list</v-icon>
    </v-badge>
    <v-icon v-else @click="toggleFiltros">filter_list</v-icon>
    </v-card-title>

    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        v-if="metadadosPlanejamentoContrato != null && carregouCampos"
        :metadados-entidade="metadadosPlanejamentoContrato"
        :ordenacao-campos="ordenacaoSelecionada"
        :campos-formulario="camposFormulario"
        :explode-hierarquia="true"
        :formulario-filtros="true"
        :ignora-obrigatorios="true"
        :ignora-inativos="false"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoContrato"
        ref="container">
        <v-col cols="12" sm="6" md="3" slot="depois">
          <v-autocomplete
            id="filtro_passo_forkflow"
            class="custom-autocomplete"
            v-model="filtrosPlanejamentoContrato.id_categoria"
            :label="`${$tc('label.categoria', 1)}`"
            :items="listaCategorias"
            clearable
            chips
            multiple
            deletable-chips
            item-text="codNomeCategoria"
            item-value="id"
            @change="verificaCategoria(filtrosPlanejamentoContrato.id_categoria)"
            :no-data-text="$tc('message.nenhum_registro', 1)"
          >
            <template v-slot:item="{ item, attrs, on }">
              <template>
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.codNomeCategoria"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3" slot="depois">
          <v-autocomplete
            id="filtro_passo_forkflow"
            class="custom-autocomplete"
            v-model="filtrosPlanejamentoContrato.id_familia"
            :label="`${$tc('label.familia', 1)}`"
            @click.native="buscaFamilias()"
            :items="listaFamilias"
            clearable
            chips
            multiple
            deletable-chips
            item-text="codNomeFamilia"
            item-value="id"
            :no-data-text="$tc('message.nenhum_registro', 1)"
          >
            <template v-slot:item="{ item, attrs, on }">
              <template>
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.codNomeFamilia"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3" slot="depois" v-if="!isAmbienteEpoca">
          <v-switch
            v-model="filtrosPlanejamentoContrato.ind_pendencia_renovacao"
            color="primary"
            :label="$tc('label.pendencia_renovacao', 1)"></v-switch>
        </v-col>
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { skipLoading } from '../../../common/functions/loading';
import { copyObject } from '../../../common/functions/helpers';

import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoContratoListFiltrosCampos from './PlanejamentoContratoListFiltrosCampos';

export default {
  name: 'PlanejamentoContratoListFiltros',
  mixins: [
    PlanejamentoContratoListFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
  },
  props: {
    contratoDuplicadoParaFiltro: {
      type: Boolean,
      default: false,
    },
    idContratoParaFiltro: Number,
  },
  computed: {
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    ordenacaoSelecionada() {
      return this.ordenacaoCampos[this.tipoFiltro] || [];
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),

      filtrosAbertos: false,
      filtrosFixados: false,
      carregouCampos: false,

      metadadosPlanejamentoContrato: null,

      ordenacaoCampos: {},
      tipoFiltro: 'comum',
      filtrosCategorias: [],
      listaCategorias: [],
      filtrosFamilias: [],
      listaFamilias: [],
      idsCategoriasSelecionadas: [],
      habilitaIconBadge: false,
      countFiltro: 0,

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosPlanejamentoContrato: {},
      workspace: {},
      tiposDeInvestimento: null,

      entidadeWorkspace: 'planejamento_contrato',

    };
  },
  methods: {
    verificaCategoria(idsCategoria) {
      if (idsCategoria) {
        this.idsCategoriasSelecionadas = idsCategoria;
      } else {
        this.idsCategoriasSelecionadas = [];
      }
    },
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosPlanejamentoContrato = this.workspace.filtros || {};
          if (this.contratoDuplicadoParaFiltro && this.filtrosPlanejamentoContrato) {
            this.filtrosPlanejamentoContrato.id_contrato = this.idContratoParaFiltro;
          }
          if (Object.values(this.filtrosPlanejamentoContrato).length > 1) {
            this.habilitaFlagBadge(true);
          }
          this.tipoFiltro = this.filtrosPlanejamentoContrato.tipoFiltro || 'comum';

          setTimeout(() => this.restaurarFiltros());
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosPlanejamentoContrato = {};
      this.habilitaIconBadge = false;
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      const parametros = {
        ...this.$refs.container.getValoresDependencias(),
        ...this.$refs.container.getValoresCamposPadrao(),
        ...this.$refs.container.getValoresCamposDinamicos(),
      };

      if (this.filtrosPlanejamentoContrato.id_categoria) {
        this.filtrosPlanejamentoContrato.id_categoria.forEach((categoria) => {
          const filtroCategoria = this.listaCategorias.filter((listaCategoria) => listaCategoria.id === categoria);
          this.filtrosCategorias.push(filtroCategoria);
        });
        parametros.categoria = this.filtrosCategorias;
        parametros.id_categoria = this.filtrosPlanejamentoContrato.id_categoria;
        this.filtrosCategorias = [];
      }
      if (this.filtrosPlanejamentoContrato.id_familia) {
        this.filtrosPlanejamentoContrato.id_familia.forEach((familia) => {
          const filtroFamilia = this.listaFamilias.filter((listaFamilia) => listaFamilia.id === familia);
          this.filtrosFamilias.push(filtroFamilia);
        });
        parametros.familia = this.filtrosFamilias;
        parametros.id_familia = this.filtrosPlanejamentoContrato.id_familia;
        this.filtrosFamilias = [];
      }
      if (!this.isAmbienteEpoca) {
        parametros.ind_pendencia_renovacao = this.filtrosPlanejamentoContrato.ind_pendencia_renovacao;
      }
      this.$emit('PlanejamentoContratoFiltros__AplicaFiltros', parametros);
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    salvarFiltrosWorkspace() {
      this.verificaCamposPreenchidosBadge();

      this.workspace.filtros = this.filtrosPlanejamentoContrato;
      this.workspace.filtros.tipoFiltro = this.tipoFiltro;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    verificaCamposPreenchidosBadge() {
      const filtroContrato = this.filtrosPlanejamentoContrato;
      if (filtroContrato.tipoFiltro) {
        delete filtroContrato.tipoFiltro;
      }
      const filtroContratos = Object.values(filtroContrato);
      this.habilitaBadge(filtroContratos);
    },
    habilitaBadge(filtroContratos) {
      if (filtroContratos.length !== 0) {
        filtroContratos.forEach((item) => {
          if (!item || (!item.length && typeof item !== 'boolean' && typeof item !== 'number')) {
            this.countFiltro += 1;
          }
        });
        if (filtroContratos.length === this.countFiltro) {
          this.filtrosPlanejamentoContrato = {};
          this.habilitaFlagBadge(false);
        } else {
          this.habilitaFlagBadge(true);
        }
        this.countFiltro = 0;
      }
    },
    habilitaFlagBadge(valor) {
      this.habilitaIconBadge = valor;
    },
    habilitaCamposExtra() {
      const chaves = Object.keys(this.ordenacaoCampos);
      chaves.forEach((chave) => {
        const campos = this.ordenacaoCampos[chave];
        if (campos.indexOf('data_fim') < 0) {
          campos.unshift('data_fim');
        }
        if (campos.indexOf('data_inicio') < 0) {
          campos.unshift('data_inicio');
        }
        if (campos.indexOf('tipo_acao') < 0) {
          campos.unshift('tipo_acao');
        }
        campos.push('descricao_passo');
        campos.splice(1, 1, 'id_contrato');
      });
    },
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro()
        .then((res) => {
          this.ordenacaoCampos = res.data;
          this.habilitaCamposExtra();
          this.carregarWorkspaceFiltros();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    buscaCategorias(autocomplete = null) {
      return this.planejamentoAcaoResource.buscarCategoria(autocomplete)
        .then((res) => {
          this.listaCategorias = res.data;
          this.carregaCamposFiltros();
        });
    },
    buscaFamilias(autocomplete = null) {
      const params = {};
      if (this.idsCategoriasSelecionadas.length > 0) {
        params.ids_categorias = this.idsCategoriasSelecionadas;
      }
      if (autocomplete && autocomplete.length) {
        params.autocomplete = autocomplete;
      }
      return this.planejamentoAcaoResource.buscarFamilia(params)
        .then((res) => {
          this.listaFamilias = res.data;
        });
    },
  },
  mounted() {
    const metadados = this.getContratoMetadado;
    this.metadadosPlanejamentoContrato = copyObject(metadados);
    this.buscaCategorias();
    this.buscaFamilias();
  },
};
</script>
<style>
  .PlanejamentoContratoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .PlanejamentoContratoFiltros__Opcoes div.v-input__slot,
  .PlanejamentoContratoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .PlanejamentoContratoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .PlanejamentoContratoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>

<template>
  <v-container pb-0 px-0 pt-0 class="PlanejamentoContratoListDadosAgrupamento">
    <v-row align="center" justify="center" row fill-height>
      <button @click="listarTodos"
        v-if="controleLayout.agrupamentos.tipoContrato"
        v-bind:class="{ selecionado: botaoSelecionado('todos') }">
        <v-icon>dvr</v-icon>
        <span>{{ $tc('label.todos', 1) }}</span>
      </button>
      <button @click="agrupaPorTipoContrato"
        v-if="controleLayout.agrupamentos.tipoContrato"
        v-bind:class="{ selecionado: botaoSelecionado('tipoContrato') }">
        <v-icon>content_paste</v-icon>
        <span>{{ $tc('label.tipo_contrato', 1) }}</span>
      </button>
      <button v-for="(d, index) in extensoes"
        v-if="controleLayout.agrupamentos.extensoes"
        @click="agrupaPorExtensao(d.label, d.indiceRecursao)"
        :key="index"
        v-bind:class="{ selecionado: botaoSelecionado(d.label) }">
        <v-icon>group_work</v-icon>
        <span>{{d.nome}}</span>
      </button>
      <button @click="agrupaPorFornecedor"
        v-if="controleLayout.agrupamentos.fornecedor"
        v-bind:class="{ selecionado: botaoSelecionado('fornecedor') }">
        <v-icon>fa-industry</v-icon>
        <span>{{ $tc('label.fornecedor', 1) }}</span>
      </button>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoContraloControleLayout from '@/spa/planejamento-contrato/list/PlanejamentoContraloControleLayout';

export default {
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      botoesAgrupamento: {},
      extensoes: [],
      indiceLabelAgrupamento: null,
    };
  },
  mixins: [PlanejamentoContraloControleLayout],
  computed: {
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    dependenciasFornecedor() {
      if (!this.getFornecedorMetadado) {
        return [];
      }

      const { mapaEntidades } = this.getFornecedorMetadado;
      return Object.values(mapaEntidades)
        .map((e) => ({ nome: e.entidadeEstrangeira, label: e.label }));
    },
  },
  methods: {
    refazBusca(agrupamento = null) {
      this.$emit('PlanejamentoContratoListDadosAgrupamento_Alterado', agrupamento);
    },
    listarTodos() {
      this.desagrupaOutros('todos');
      this.refazBusca('todos');
    },
    agrupaPorTipoContrato() {
      this.desagrupaOutros('tipoContrato');
      this.refazBusca();
    },
    agrupaPorFornecedor() {
      this.desagrupaOutros('fornecedor');
      this.refazBusca();
    },
    agrupaPorExtensao(labelExtensao, indiceRecursao) {
      this.desagrupaOutros(labelExtensao);
      this.indiceLabelAgrupamento = indiceRecursao;
      this.refazBusca();
    },
    desagrupaOutros(labelAgrupamento) {
      Object.keys(this.botoesAgrupamento)
        .filter((k) => k !== labelAgrupamento)
        .forEach((k) => {
          this.botoesAgrupamento[k] = false;
        });
      this.botoesAgrupamento[labelAgrupamento] = true;
      setTimeout(() => this.$forceUpdate());
    },
    botaoSelecionado(nomeBotao) {
      return this.botoesAgrupamento[nomeBotao];
    },
    agrupamentoPadraoPorTipo() {
      this.botoesAgrupamento.todos = false;
      this.botoesAgrupamento.tipoContrato = true;
      this.botoesAgrupamento.fornecedor = false;
      this.extensoes.forEach((d) => {
        this.botoesAgrupamento[d.label] = false;
      });

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoPadraoPorExtensao() {
      this.botoesAgrupamento.todos = false;
      this.botoesAgrupamento.tipoContrato = false;
      this.botoesAgrupamento.fornecedor = false;

      this.extensoes.forEach((d) => {
        this.botoesAgrupamento[d.label] = false;
      });

      const primeiraDependencia = this.extensoes[0];
      this.botoesAgrupamento[primeiraDependencia.label] = true;

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoPadraoTodos() {
      this.botoesAgrupamento.todos = true;
      this.botoesAgrupamento.tipoContrato = false;
      this.botoesAgrupamento.fornecedor = false;
      this.extensoes.forEach((d) => {
        this.botoesAgrupamento[d.label] = false;
      });

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoHabilitado() {
      const agrupamento = Object.keys(this.botoesAgrupamento)
        .filter((k) => this.botoesAgrupamento[k])[0];
      return agrupamento;
    },
    buscarExtensoes() {
      this.extensoes = this.dependenciasFornecedor;

      this.configuracaoResource
        .buscarLabelsCampoCliente()
        .then((res) => {
          res.data.forEach((campo) => {
            this.extensoes.push(campo);
          });
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  beforeMount() {
    this.buscarExtensoes();
  },
};
</script>
<style>
.PlanejamentoContratoListDadosAgrupamento {
  width: 100%;
  max-width: initial;
}
.PlanejamentoContratoListDadosAgrupamento i,
.PlanejamentoContratoListDadosAgrupamento span {
  width: 100%;
}
.PlanejamentoContratoListDadosAgrupamento button {
  margin-right: 10px;
  padding: 4px;
  font-size: 0.7em;
}
.PlanejamentoContratoListDadosAgrupamento button.selecionado {
  color: var(--v-primary-darken1);
}
.PlanejamentoContratoListDadosAgrupamento button.selecionado i {
  color: var(--v-primary-darken1);
}
.PlanejamentoContratoListDadosAgrupamento button:not(.selecionado):hover {
  color: var(--v-grey-darken1);
}
.PlanejamentoContratoListDadosAgrupamento button:not(.selecionado):hover i {
  color: var(--v-grey-darken1);
}
</style>

<template lang="html">
  <v-dialog v-model="showModal" max-width="350px">
    <v-card>
      <v-card-title >
        <span class="headline font-weight-black">
          {{ $t('label.atencao') }}
        </span>
      </v-card-title>
      <v-card-text class="pb-1">
        <span >
          {{ $t('message.deseja_encerrar_contrato') }}
        </span>
      </v-card-text>
      <div >

      <v-form ref="form" lazy-validation autocomplete="off">
            <v-col cols="12" class="px-6 pt-0 pb-0">
              <v-textarea
              name="justificativa"
              :label="`${$tc('label.justificativa', 1)}`"
              v-model="justificativa"
              auto-grow
              autofocus
              rows="1"
              maxlength="150"
            ></v-textarea>
          </v-col>
      </v-form>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn id="nao" color="primary darken-1"  text @click.native="close">
          {{$t('label.nao')}}
        </v-btn>
        <v-btn id="sim" color="primary darken-1" text @click.native="agree">
          {{$t('label.sim')}}
        </v-btn>
      </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'ConfirmEncerramentoContrato',
  data() {
    return {
      showModal: false,
      justificativa: '',
    };
  },
  computed: {
  },
  props: {
    tituloModal: String,
    contrato: Object,
  },
  methods: {
    open() {
      this.showModal = true;
    },
    close() {
      this.resetCampos();
      this.showModal = false;
    },
    agree() {
      if (!this.$refs.form.validate()) return;
      let param = {};
      param = {
        justificativa: this.justificativa ? this.justificativa : this.$tc('label.nao_informado'),
        id: this.contrato.id,
      };
      this.$emit('agree', param);
      this.close();
    },
    resetCampos() {
      this.justificativa = '';
    },
  },
};
</script>
<style>
  .plan_acao_prazo_vencimento .v-messages__message {
    color: #7a7978;
  }
</style>

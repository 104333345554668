export default {
  data() {
    return {
      botoesCustomizados: [],
    };
  },
  methods: {
    exibicaoCancelarPersonalizada() {
      return true;
    },
    exibicaoReprovarPersonalizada() {
      return true;
    },
  },
};
